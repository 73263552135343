import type { Observable } from "rxjs";
import { catchError, from, map, merge, of, switchMap } from "rxjs";
import { GET, POST } from "~/async/fetch";
import { ofType } from "~/state/epics";
import type { WorkItem } from "@fscrypto/domain/work-item";
import { $path } from "remix-routes";
import type { ExplorerTabData } from "./explorer-tabs-machine";

export type Event =
  | FetchTabs
  | FetchTabsSuccess
  | FetchTabsFailure
  | UpdateTabs
  | UpdateTabsSuccess
  | UpdateTabsFailure;

type FetchTabs = { type: "EXPLORER_TABS.EPIC.FETCH" };
type FetchTabsSuccess = {
  type: "EXPLORER_TABS.EPIC.FETCH_SUCCESS";
  payload: WorkItem[];
};
type FetchTabsFailure = { type: "EXPLORER_TABS.EPIC.FETCH_FAILURE"; payload: Error };

type UpdateTabs = { type: "EXPLORER_TABS.EPIC.UPDATE"; payload: ExplorerTabData[] };
type UpdateTabsSuccess = {
  type: "EXPLORER_TABS.EPIC.UPDATE_SUCCESS";
  payload: WorkItem[];
};
type UpdateTabsFailure = { type: "EXPLORER_TABS.EPIC.UPDATE_FAILURE"; payload: Error };

export const createEpics = (actions$: Observable<Event>): Observable<Event> => {
  return merge(getTabsEpic(actions$), updateTabsEpic(actions$));
};

const getTabsEpic = (actions$: Observable<Event>): Observable<Event> => {
  return actions$.pipe(
    ofType("EXPLORER_TABS.EPIC.FETCH"),
    switchMap(() => {
      return from(getTabs()).pipe(
        map((response) => ({
          type: "EXPLORER_TABS.EPIC.FETCH_SUCCESS",
          payload: response,
        })),
        catchError((error) => {
          return of({
            type: "EXPLORER_TABS.EPIC.FETCH_FAILURE",
            payload: error,
          });
        }),
      );
    }),
  ) as Observable<Event>;
};

const updateTabsEpic = (actions$: Observable<Event>): Observable<Event> => {
  return actions$.pipe(
    ofType("EXPLORER_TABS.EPIC.UPDATE"),
    switchMap((action) => {
      return from(updateTabs(action.payload)).pipe(
        map((response) => ({
          type: "EXPLORER_TABS.EPIC.UPDATE_SUCCESS",
          payload: response,
        })),
        catchError((error) => {
          return of({
            type: "EXPLORER_TABS.EPIC.UPDATE_FAILURE",
            payload: error,
          });
        }),
      );
    }),
  ) as Observable<Event>;
};

// ASYNC API Calls

const getTabs = async () => {
  try {
    const { items } = await GET<{ items: WorkItem[] }>($path("/api/work-items/tabs/get"));
    return items;
  } catch (e) {
    throw new Error(`Error Fetching Explorer Tabs`);
  }
};

const updateTabs = async (tabs: ExplorerTabData[]) => {
  try {
    const { items } = await POST<{ items: WorkItem[] }>($path("/api/work-items/tabs/set"), {
      explorerTabs: createTabsFromWorkItems(tabs),
    });
    return items;
  } catch (e) {
    throw new Error(`Error Updating Explorer Tabs`);
  }
};

const createTabsFromWorkItems = (tabs: ExplorerTabData[]) => {
  return tabs.map((tab) => {
    return {
      typeId: tab.id,
      active: false,
      type: tab.type,
    };
  });
};
