import type { ActorRefFrom, AnyActorRef } from "xstate";
import { createMachine, assign, raise, spawn } from "xstate";
import { actorSystem } from "~/state";
import type { WorkItem, WorkItemType } from "@fscrypto/domain/work-item";
import { uniqBy } from "lodash-es";
import type { GlobalEvent } from "~/state/events";
import { eventBus } from "~/state/events";
import type { WorkItemsActorRef } from "~/state/machines/work-items/work-items";
import { useActorFromSystem } from "~/state/system";
import { useEffect } from "react";
import { getExplorerTabUrl } from "~/features/explorer-tabs/utils/get-explorer-tab-url";
import { getFileTypeAndIdFromUrl } from "~/features/explorer-tabs/utils/get-file-type-and-id-from-url";
import { Subject } from "rxjs";
import type { Event as EpicEvent } from "./epics";
import { createEpics } from "./epics";

export const createExplorerTabsMachine = (items: WorkItem[]) => {
  const actions$ = new Subject<EpicEvent>();
  const machine = createMachine(
    {
      id: "ExplorerTabs",
      predictableActionArguments: true,
      tsTypes: {} as import("./explorer-tabs-machine.typegen").Typegen0,
      schema: {
        context: {} as ExplorerTabsContext,
        events: {} as ExplorerTabsEvent | GlobalEvent | EpicEvent,
      },
      invoke: {
        id: "global-events",
        src: "globalEvents",
      },
      context: {
        tabs: [],
      },
      initial: "initialize",
      entry: ["setupEpic"],
      on: {
        "EXPLORER_TABS.UPDATE_TABS": {
          actions: ["updateTabs"],
        },
        "EXPLORER_TABS.ADD_INITIAL_TAB": {
          description:
            "a user can go directly to a route without the tab being present in the work item list. This will add it to the tab list if it`s not there",
          actions: ["addInitialTabIfNotPresent"],
        },
        "WORK_ITEMS.REMOVE": {
          description:
            "This is a global event that is fired when a work item is removed from the work item list. This is used to remove the tab if it is present.",
          actions: ["globalRemoveTab"],
        },
        "WORK_ITEMS.REMOVE_MANY": [
          {
            description:
              "This is a global event that is fired when a work item is removed from the work item list. This is used to remove the tab if it is present.",
            actions: ["removeManyTabs", "navigateToLastTab"],
            cond: "containsActiveTab",
          },
          {
            actions: ["removeManyTabs"],
          },
        ],
        "EXPLORER_TABS.REMOVE_TAB": [
          {
            target: "debounce",
            actions: ["removeTab"],
            cond: "isNotActiveTab",
          },
          {
            target: "debounce",
            description: "If the tab to be removed is active, navigate to the next tab",
            actions: ["removeTab", "navigateToLastTab"],
          },
        ],
      },
      states: {
        initialize: {
          entry: ["addInitialTabs"],
          always: "idle",
        },
        fetching: {
          entry: ["fetchTabsEpic"],
          on: {
            "EXPLORER_TABS.ADD_TAB": {
              description: "This allows the tab to be added from the route level before the tabs are fetched",
              actions: ["addTab"],
            },
            "EXPLORER_TABS.EPIC.FETCH_SUCCESS": {
              target: "idle",
              actions: ["handleFetchSuccess"],
            },
          },
        },
        debounce: {
          on: {
            "EXPLORER_TABS.ADD_TAB": {
              target: "debounce",
              actions: ["addTab"],
            },
            "EXPLORER_TABS.REORDER_TABS": {
              target: "debounce",
              actions: ["reorderTabs"],
            },
          },
          after: {
            1000: "saveTabs",
          },
        },
        saveTabs: {
          entry: ["updateTabsEpic"],
          on: {
            "EXPLORER_TABS.EPIC.UPDATE_SUCCESS": {
              target: "idle",
            },
          },
        },
        idle: {
          on: {
            "EXPLORER_TABS.REORDER_TABS": {
              target: "debounce",
              actions: ["reorderTabs"],
            },
            "EXPLORER_TABS.ADD_TAB": {
              target: "debounce",
              actions: ["addTab"],
            },
            "EXPLORER_TABS.CLOSE_ALL": {
              description:
                "Close all tabs except the active tab. For better aesthetics, we also disable the view of the action buttons while the animation of the tabs is happening.",
              actions: ["closeAllTabs"],
              target: "disableActions",
            },
          },
        },
        disableActions: {
          after: { 800: "debounce" },
        },
      },
    },
    {
      actions: {
        setupEpic: assign((ctx, _e) => {
          if (ctx.epic$) return {};
          const epic$ = createEpics(actions$);
          return {
            epic$: spawn(epic$),
          };
        }),
        addInitialTabs: assign((context) => {
          const workItemsRef = actorSystem.get<WorkItemsActorRef>("workItems")!;
          workItemsRef?.send({ type: "WORK_ITEMS.ADD_MANY", payload: items });
          return {
            tabs: uniqBy(
              [...createTabsFromWorkItems(items), ...(context.initialTab ? [context.initialTab] : [])],
              "id",
            ),
          };
        }),
        updateTabsEpic: (ctx) => {
          actions$.next({ type: "EXPLORER_TABS.EPIC.UPDATE", payload: ctx.tabs });
        },
        updateTabs: assign((context, event) => {
          const updatedId = event.payload.id;
          const updatedTab = context.tabs.find((tab) => tab.id === updatedId);
          if (!updatedTab) return context;
          return {
            tabs: context.tabs.map((tab) => {
              if (tab.id === updatedId) {
                return { ...tab, name: event.payload.name };
              }
              return tab;
            }),
          };
        }),
        addInitialTabIfNotPresent: assign((context, event) => {
          const { id, name, type } = event.tab;
          const tabExists = context.tabs.some((tab) => tab.id === id);
          if (tabExists) return context;
          return {
            tabs: [{ id, name, type }, ...context.tabs],
          };
        }),
        addTab: assign((context, event) => {
          const { id, name, type } = event.tab;
          if (type === "dashboard" || type === "query") {
            const tabExists = context.tabs.some((tab) => tab.id === id);
            if (tabExists) return context;
            return {
              tabs: [...context.tabs, { id, name, type }],
            };
          }
          return context;
        }),
        reorderTabs: assign((context, event) => {
          const ids = event.payload;
          const newTabs = ids
            .map((id) => context.tabs.find((tab) => tab.id === id))
            .filter(Boolean) as ExplorerTabData[];
          return {
            tabs: newTabs,
          };
        }),
        removeTab: assign((context, event) => {
          const id = event.payload;
          return {
            tabs: context.tabs.filter((tab) => tab.id !== id),
          };
        }),
        navigateToLastTab: (context) => {
          const lastTab = context.tabs[context.tabs.length - 1];
          const navigateRef = actorSystem.get("navigate")!;
          const to = lastTab
            ? getExplorerTabUrl(lastTab.id, lastTab.type, window.location.search)
            : "/edit?closing=true";
          navigateRef.send({
            type: "NAVIGATE.NAVIGATE_TO",
            payload: to,
          });
        },
        closeAllTabs: assign((context) => {
          const { fileId } = getFileTypeAndIdFromUrl();
          return {
            tabs: context.tabs.filter((tab) => tab.id === fileId),
          };
        }),
        //@ts-ignore
        globalRemoveTab: raise((context, event) => {
          const { id } = event.payload;
          if (context.tabs.some((tab: ExplorerTabData) => tab.id === id)) {
            return {
              type: "EXPLORER_TABS.REMOVE_TAB",
              payload: id,
            };
          }
          return {};
        }),
        removeManyTabs: assign((context, event) => {
          const workItems = event.payload;
          const newTabs = context.tabs.filter((tab) => !workItems.some((workItem) => workItem.id === tab.id));
          return {
            tabs: newTabs,
          };
        }),
      },
      services: {
        globalEvents: () => eventBus.events$,
      },
      guards: {
        isNotActiveTab: (context, event) => {
          const id = event.payload;
          const { fileId } = getFileTypeAndIdFromUrl();
          return id !== fileId;
        },
        containsActiveTab: (context, event) => {
          const { fileId } = getFileTypeAndIdFromUrl();
          return event.payload.some((tab) => tab.id === fileId);
        },
      },
    },
  );
  return machine;
};

interface ExplorerTabsContext {
  tabs: ExplorerTabData[];
  initialTab?: ExplorerTabData;
  epic$?: AnyActorRef;
}

type ExplorerTabsEvent =
  | {
      type: "EXPLORER_TABS.REORDER_TABS";
      payload: string[];
    }
  | {
      type: "EXPLORER_TABS.REMOVE_TAB";
      payload: string;
    }
  | {
      type: "EXPLORER_TABS.ADD_TAB";
      tab: ExplorerTabData;
    }
  | {
      type: "EXPLORER_TABS.ADD_INITIAL_TAB";
      tab: ExplorerTabData;
    }
  | {
      type: "EXPLORER_TABS.CLOSE_ALL";
    }
  | {
      type: "EXPLORER_TABS.UPDATE_TABS";
      payload: ExplorerTabData;
    };

export type ExplorerTabsRef = ActorRefFrom<ReturnType<typeof createExplorerTabsMachine>>;

export type ExplorerTabData = {
  type: WorkItemType;
  id: string;
  name: string;
};

type Ref = ActorRefFrom<typeof createExplorerTabsMachine>;

const createExplorerTabs = (items: WorkItem[]) => {
  let ref = actorSystem.get<Ref>(`explorerTabs`);
  if (!ref) {
    const machine = createExplorerTabsMachine(items);
    ref = actorSystem.registerMachine(machine, `explorerTabs`);
  }
  return ref;
};

export const useTabs = (items?: WorkItem[]) => {
  const [state, ref] = useActorFromSystem<Ref>("explorerTabs");

  useEffect(() => {
    if (ref) return;
    createExplorerTabs(items ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  if (!ref || !state)
    return {
      isReady: false,
    } as const;

  const tabs = state.context.tabs;
  const isDisabled = state.matches("disableActions");

  return {
    isReady: true,
    tabs,
    addTab: (tab: ExplorerTabData) => {
      ref.send({ type: "EXPLORER_TABS.ADD_TAB", tab });
    },
    addInitialTab: (tab: ExplorerTabData) => {
      ref.send({ type: "EXPLORER_TABS.ADD_INITIAL_TAB", tab });
    },
    removeTab: (id: string) => ref.send({ type: "EXPLORER_TABS.REMOVE_TAB", payload: id }),
    closeAll: () => ref.send({ type: "EXPLORER_TABS.CLOSE_ALL" }),
    isDisabled,
    reorderTabs: (ids: string[]) => ref.send({ type: "EXPLORER_TABS.REORDER_TABS", payload: ids }),
  };
};

export const createTabsFromWorkItems = (workItems: WorkItem[]): ExplorerTabData[] => {
  return workItems
    .map((workItem) => ({
      type: workItem.typename,
      id: workItem.id,
      name: workItem.name,
    }))
    .filter((item) => item.type === "dashboard" || item.type === "query");
};
